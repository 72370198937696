<template>
  <section>
    <v-container class="container-custom">
      <v-row>
        <v-col md="12">
          <h1>Disclaimer</h1>
          <p>Updated at 2022-05-16</p>

          <p>
            Evdrivein hereby grants you access to www.evinnepal.com (“the
            Website”) and invites you to purchase the services offered here.
          </p>

          <h1>Definitions and key terms</h1>
          <p>
            To help explain things as clearly as possible in this Disclaimer,
            every time any of these terms are referenced, are strictly defined
            as:
          </p>
          <ul>
            <li>
              Cookie: small amount of data generated by a website and saved by
              your web browser. It is used to identify your browser, provide
              analytics, remember information about you such as your language
              preference or login information.
            </li>
            <li>
              Company: when this policy mentions “Company,” “we,” “us,” or
              “our,” it refers to Evdrivein Pvt. Ltd., Kathmandu, Nepal that is
              responsible for your information under this Disclaimer.
            </li>
            <li>
              Service: refers to the service provided by Evdrivein as described
              in the relative terms (if available) and on this platform.
            </li>
            <li>
              Website: site, which can be accessed via this URL:
              www.evinnepal.com
            </li>
            <li>
              You: a person or entity that is registered with Evdrivein to use
              the Services.
            </li>
          </ul>

          <h1>Limited liability</h1>
          <p>
            Evdrivein endeavours to update and/or supplement the content of the
            website/app on a regular basis. Despite our care and attention,
            content may be incomplete and/or incorrect.
          </p>
          <p>
            The materials offered on the website/app are offered without any
            form of guarantee or claim to their correctness. These materials can
            be changed at any time without prior notice from Evdrivein.
          </p>
          <p>
            Particularly, all prices on the website/app are stated subject to
            typing and programming errors. No liability is assumed for the
            implications of such errors. No agreement is concluded on the basis
            of such errors.
          </p>
          <p>
            Evdrivein shall not bear any liability for hyperlinks to websites or
            services of third parties included on the website/app. From our
            website/app, you can visit other websites by following hyperlinks to
            such external sites. While we strive to provide only quality links
            to useful and ethical websites, we have no control over the content
            and nature of these sites. These links to other websites do not
            imply a recommendation for all the content found on these sites.
            Site owners and content may change without notice and may occur
            before we have the opportunity to remove a link which may have gone
            'bad'.
          </p>
          <p>
            Please be also aware that when you leave our website/app, other
            sites may have different privacy policies and terms which are beyond
            our control. Please be sure to check the Privacy Policies of these
            sites as well as their "Terms of Service" before engaging in any
            business or uploading any information.
          </p>
          <h1>Links to Other Websites Disclaimer</h1>
          <p>
            This Disclaimer applies only to the Services. The Services may
            contain links to other websites not operated or controlled by
            Evdrivein. We are not responsible for the content, accuracy or
            opinions expressed in such websites, and such websites are not
            investigated, monitored or checked for accuracy or completeness by
            us. Please remember that when you use a link to go from the Services
            to another website, our Privacy Policy is no longer in effect. Your
            browsing and interaction on any other website, including those that
            have a link on our platform, is subject to that website's own rules
            and policies. Such third parties may use their own cookies or other
            methods to collect information about you. If You click on a third
            party link, You will be directed to that third party's site. We
            strongly advise You to review the Privacy Policy and Terms of every
            site You visit.
          </p>
          <p>
            We have no control over and assume no responsibility for the
            content, privacy policies or practices of any third party sites or
            services.
          </p>
          <h1>Errors and Omissions Disclaimer</h1>
          <p>
            Evdrivein is not responsible for any content, code or any other
            imprecision.
          </p>
          <p>Evdrivein does not provide warranties or guarantees.</p>
          <p>
            In no event shall Evdrivein be liable for any special, direct,
            indirect, consequential, or incidental damages or any damages
            whatsoever, whether in an action of contract, negligence or other
            tort, arising out of or in connection with the use of the Service or
            the contents of the Service. Evdrivein reserves the right to make
            additions, deletions, or modifications to the contents on the
            Service at any time without prior notice.
          </p>

          <h1>General Disclaimer</h1>
          <p>
            The Evdrivein Service and its contents are provided "as is" and "as
            available" without any warranty or representations of any kind,
            whether express or implied. Evdrivein is a distributor and not a
            publisher of the content supplied by third parties; as such,
            Evdrivein exercises no editorial control over such content and makes
            no warranty or representation as to the accuracy, reliability or
            currency of any information, content, service or merchandise
            provided through or accessible via the Evdrivein Service. Without
            limiting the foregoing, Evdrivein specifically disclaims all
            warranties and representations in any content transmitted on or in
            connection with the Evdrivein Service or on sites that may appear as
            links on the Evdrivein Service, or in the products provided as a
            part of, or otherwise in connection with, the Evdrivein Service,
            including without limitation any warranties of merchantability,
            fitness for a particular purpose or non-infringement of third party
            rights. No oral advice or written information given by Evdrivein or
            any of its affiliates, employees, officers, directors, agents, or
            the like will create a warranty. Price and availability information
            is subject to change without notice. Without limiting the foregoing,
            Evdrivein does not warrant that the Evdrivein Service will be
            uninterrupted, uncorrupted, timely, or error-free.
          </p>
          <h1>Copyright Disclaimer</h1>
          <p>
            All intellectual property rights concerning these materials are
            vested in Evdrivein. Copying, distribution and any other use of
            these materials is not permitted without the written permission of
            Evdrivein, except and only to the extent otherwise provided in
            regulations of mandatory law (such as the right to quote), unless
            otherwise stated for certain materials.
          </p>

          <h1>Legal Disclosure</h1>
          <p>
            The materials, any comments or information provided by Evdrivein are
            for educational purposes only and nothing conveyed or provided
            should be considered legal, accounting or tax advice. You are
            responsible for whatever you may do with the information you obtain
            from Evdrivein. As such, by visiting and using this website/app you
            acknowledge and agree that you have been assessed by a qualified law
            expert (i.e your attorney) who has given you consent to take part in
            any legal activity. Please contact your own attorney, accountant or
            tax professional with any specific questions you have related to the
            information provided that are of legal, accounting or tax nature.
          </p>
          <h1>Financial Disclosure</h1>
          <p>
            Gross merchandise sales is a non-GAAP metric. We use it to express
            the total demand across all of our web sites and stores. This number
            measures the dollar value of the order placed in the year before
            accruing for certain items such as returns, and it ignores certain
            timing cut-offs that are required by GAAP for revenue recognition
            purposes. If we were a public company, we would have to reconcile
            gross merchandise sales to the nearest GAAP metric (net sales), but
            we are currently a private company so the gross merchandise sales
            number should be viewed just as an interesting number that we want
            to share with our friends.
          </p>
          <p>
            This document contains forward-looking statements that involve risks
            and uncertainties, as well as assumptions that, if they ever
            materialise or prove incorrect, could cause or results to differ
            materially from those expressed or implied by the forward-looking
            statements and assumptions. These risks and uncertainties include,
            but are not limited to, the risk of economic slowdown, the risk of
            over or underbuying, the risk of consumers not shopping online at
            our website at the rate we expected, the risk of supplier shortages,
            the risk of new or growing competition, the risk of natural or some
            other type of disaster affecting our fulfilment of historical
            operations or web servers, and the risk of the world generally
            coming to an end. All statements other than statements historical
            fact are statements that could be deemed forward-looking statements,
            including statements of expectation or belief; and any statement of
            assumptions underlying any of the foregoing. Evdrivein assumes no
            obligation and does not intend to update these forward-looking
            statements.
          </p>
          <h1>Educational Disclosure</h1>
          <p>
            Any Information provided by Evdrivein is for educational purposes
            only, and is not to be interpreted as a recommendation for a
            specific treatment plan, product, or course of action. Evdrivein is
            a distributor and not a publisher of the content supplied by third
            parties; as such, Evdrivein exercises no editorial control over such
            content and makes no warranty or representation as to the accuracy,
            reliability or currency of any information or educational content
            provided through or accessible via Evdrivein. Without limiting the
            foregoing, Evdrivein specifically disclaims all warranties and
            representations in any content transmitted on or in connection with
            Evdrivein or on sites that may appear as links on Evdrivein, or in
            the products provided as a part of, or otherwise in connection with,
            the Evdrivein. No oral advice or written information given by
            Evdrivein or any of its affiliates, employees, officers, directors,
            agents, or the like will create a warranty.
          </p>
          <h1>Advertising Disclosure</h1>
          <p>
            This website/app may contain third party advertisements and links to
            third party sites. Evdrivein does not make any representation as to
            the accuracy or suitability of any of the information contained in
            those advertisements or sites and does not accept any responsibility
            or liability for the conduct or content of those advertisements and
            sites and the offerings made by the third parties.
          </p>
          <p>
            Advertising keeps Evdrivein and many of the websites and services
            you use free of charge. We work hard to make sure that ads are safe,
            unobtrusive, and as relevant as possible.
          </p>
          <p>
            Third party advertisements and links to other sites where goods or
            services are advertised are not endorsements or recommendations by
            Evdrivein of the third party sites, goods or services. Evdrivein
            takes no responsibility for the content of any of the ads, promises
            made, or the quality/reliability of the products or services offered
            in all advertisements.
          </p>
          <h1>Your Consent</h1>
          <p>
            We've updated our Disclaimer to provide you with complete
            transparency into what is being set when you visit our site and how
            it's being used. By using our website/app, registering an account,
            or making a purchase, you hereby consent to our Disclaimer and agree
            to its terms.
          </p>

          <h1>Changes To Our Disclaimer</h1>
          <p>
            Should we update, amend or make any changes to this document so that
            they accurately reflect our Service and policies. Unless otherwise
            required by law, those changes will be prominently posted here.
            Then, if you continue to use the Service, you will be bound by the
            updated Disclaimer. If you do not want to agree to this or any
            updated Disclaimer, you can delete your account.
          </p>
          <h1>Contact Us</h1>
          <p>
            Don't hesitate to contact us if you have any questions regarding
            this Disclaimer.
          </p>
          <ul>
            <li>
              Via Email:
              <a
                href="/cdn-cgi/l/email-protection"
                class="__cf_email__"
                data-cfemail="4b383e3b3b24393f0b2e3d2225252e3b2a2765282426"
                >[email&#160;protected]</a
              >
            </li>
            <li>Via this Link: www.evinnepal.com</li>
          </ul>
        </v-col>
      </v-row>
    </v-container>
  </section>
</template>

<script>
export default {
  name: "PrivaryPolicy",
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/global.scss";
</style>